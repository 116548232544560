export const currentEnvironment = (): string => {
  const { hostname } = window.location;

  switch (hostname) {
    case 'monit-cash.vercel.app':
    case 'monit-webapp.vercel.app':
    case 'monit-vendor.vercel.app':
    case 'pay.monitpay.com':
    case 'vendor.monitpay.com':
    case 'pay.monit.id':
      return 'production';
    case 'staging.pay.monitpay.com':
    case 'staging.web.monitpay.com':
    case 'staging.cash.monitpay.com':
    case 'staging.webadmin.monitpay.com':
    case 'staging.pay.monit.id':
    case 'staging.vendor.monitpay.com':
    case 'test.cash.monitpay.com':
      return 'staging';
    case 'monit-pay-git-staging-monit.vercel.app':
    case 'monit-card-git-monit-card-monit.vercel.app':
      return 'preview';
    case 'localhost':
      return 'staging';
    case 'webadmin.monitpay.com':
    case 'cash.monitpay.com':
    case 'monit-card-git-fix-load-more-monit.vercel.app':
    case 'monit-card-git-style-ui-adjustment-2-monit.vercel.app':
    case 'web.monitpay.com':
      return 'cloudrun';
    default:
      throw new Error(
        `No environment is defined for this hostname: ${hostname}.`
      );
  }
};

export const platformApiUrl = (environment: string) => {
  switch (environment.toLowerCase()) {
    case 'production':
      return `https://platform.monitpay.com`;
    case 'github-staging':
    case 'staging':
      return `https://staging.platform.monitpay.com`;
    case 'development':
      return `http://localhost:8000`;
    case 'staging2':
      return `https://api2.monitpay.com`;
    default:
      throw new Error(
        `No API URL has been defined for this environment: ${environment}.`
      );
  }
};

export const cashAdminApiUrl = (environment: string) => {
  switch (environment.toLowerCase()) {
    case 'production':
      return `https://api.monitpay.com`;
    case 'staging':
      return `https://staging.api.monitpay.com`;
    case 'cloudrun':
      return `https://apiv2.monitpay.com`;
    case 'development':
      return `http://localhost:8200`;
    case 'staging2':
      return `https://api2.monitpay.com`;
    default:
      throw new Error(
        `No API URL has been defined for this environment: ${environment}.`
      );
  }
};
export const cashVendorApiUrl = (environment: string) => {
  switch (environment.toLowerCase()) {
    case 'production':
      return `https://api.monitpay.com`;
    case 'staging':
      return `https://staging.api.monitpay.com`;
    case 'development':
      return `http://localhost:8200`;
    case 'cloudrun':
      return `https://apiv2.monitpay.com`;
    case 'staging2':
      return `https://api2.monitpay.com`;
    default:
      throw new Error(
        `No API URL has been defined for this environment: ${environment}.`
      );
  }
};

export const cashApiUrl = (environment: string) => {
  switch (environment.toLowerCase()) {
    case 'production':
      return `https://api.monitpay.com`;
    case 'preview':
    case 'staging':
      return `https://staging.api.monitpay.com`;
    case 'development':
      return `http://localhost:8200`;
    case 'cloudrun':
      return `https://apiv2.monitpay.com`;
    case 'staging2':
      return `https://api2.monitpay.com`;
    default:
      throw new Error(
        `No API URL has been defined for this environment: ${environment}.`
      );
  }
};
export const payApiUrl = (environment: string) => {
  switch (environment.toLowerCase()) {
    case 'production':
      return `https://api.monitpay.com`;
    case 'preview':
    case 'staging':
      return `https://staging.api.monitpay.com`;
    case 'development':
      return `http://localhost:8200`;
    case 'cloudrun':
      return `https://apiv2.monitpay.com`;
    case 'staging2':
      return `https://api2.monitpay.com`;
    default:
      throw new Error(
        `No API URL has been defined for this environment: ${environment}.`
      );
  }
};

export const paymentLinkUrl = (environment: string) => {
  const url = process.env.REACT_APP_MONIT_PAYMENT_LINK_URL;
  if (url) {
    return url;
  }

  switch (environment.toLowerCase()) {
    case 'production-test':
    case 'cloudrun':
    case 'production':
      return `https://pay.monit.id`;
    case 'staging':
      return `https://staging.pay.monit.id`;
    case 'preview':
      return `https://monit-pay-git-staging-monit.vercel.app`;
    case 'development':
      return `http://localhost:3300`;
    case 'staging2':
      return `https://api2.monitpay.com`;
    default:
      throw new Error(
        `No API URL has been defined for this environment: ${environment}.`
      );
  }
};
export const vendorUrl = (environment: string) => {
  switch (environment.toLowerCase()) {
    case 'production':
      return `https://vendor.monitpay.com`;
    case 'staging':
      return `https://staging.vendor.monitpay.com`;
    case 'development':
      return `http://localhost:3400`;
    case 'cloudrun':
      return `https://apiv2.monitpay.com`;
    default:
      throw new Error(
        `No API URL has been defined for this environment: ${environment}.`
      );
  }
};

export const cardUrl = (environment: string) => {
  switch (environment.toLowerCase()) {
    case 'cloudrun':
    case 'production':
      return `https://web.monitpay.com`;
    case 'staging':
      return `https://staging.web.monitpay.com`;
    case 'development':
      return `http://localhost:3500`;
    case 'preview':
      return `http://monit-card-git-monit-card-monit.vercel.app`;
    case 'staging2':
      return `https://api2.monitpay.com`;
    default:
      throw new Error(
        `No API URL has been defined for this environment: ${environment}.`
      );
  }
};

export const publicStorageUrl = (url: string) => {
  let hostname = 'https://storage.googleapis.com/monit-public';
  if (process.env.REACT_APP_PUBLIC_STORAGE_URL) {
    hostname = process.env.REACT_APP_PUBLIC_STORAGE_URL;
  }
  return `${hostname}${url}`;
};
