// eslint-disable-next-line simple-import-sort/imports
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import { Settings } from 'luxon';
import { scan } from 'react-scan';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Settings.defaultLocale = 'en-US';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { currentEnvironment } from 'sharedv2/utilities/environment';

import * as serviceWorker from './serviceWorker';
const tagManagerArgs = {
  gtmId: 'GTM-TCDB4T2W',
};

scan({
  enabled: currentEnvironment() == 'development',
});

if (
  ['cash.monitpay.com', 'web.monitpay.com'].includes(window.location.hostname)
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: ['https://apiv2.monitpay.com/graphql/'],
        unblock: ['.sentry-unblock, [data-sentry-unblock]'],
        unmask: ['.sentry-unmask, [data-sentry-unmask]'],
      }),
      // Session Replay
    ],
    ignoreErrors: ['ChunkLoadError'],
    release: process.env.REACT_APP_RELEASE_VERSION,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, //

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.7,
  });
  if (window.location.hostname == 'web.monitpay.com') {
    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize('G-9TNFXJZM5W');
  }

  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1,
  });

  amplitude.add(sessionReplayTracking);
  amplitude.init('beec67e78044212ae79f44b59b4c6b66', {
    fetchRemoteConfig: true,
    autocapture: true,
  });
}

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === 'cashadmin') {
    return import('./cashadmin/App');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'cash') {
    return import('./cash/App');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'pay') {
    return import('./payment/App');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'portal') {
    return import('./vendor-portal/App');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'card') {
    return import('./card/App');
  } else {
    return Promise.reject(
      new Error('No such build target: ' + process.env.REACT_APP_BUILD_TARGET)
    );
  }
}

importBuildTarget().then(({ default: Environment }) => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(<Environment />);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
